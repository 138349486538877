<template>
	<div>
		<!-- Spinner -->
		<div v-if="isloading">
			<spinner></spinner>
		</div>
		<v-row class="mt-10 mb-">
			<!-- filter payroll -->
			<v-col cols="12" sm="3" md="3">
				<v-menu
					ref="menu"
					v-model="menu"
					:close-on-content-click="false"
					:return-value.sync="date"
					transition="scale-transition"
					offset-y
					max-width="290px"
					min-width="auto"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							dense
							solo
							flat
							clearable
							background-color="white"
							placeholder="yyyy-mm"
							v-model="date"
							@click:clear="resetData"
							prepend-inner-icon="mdi-calendar"
							readonly
							v-bind="attrs"
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker v-model="date" type="month" no-title scrollable>
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="menu = false">
							Cancel
						</v-btn>
						<v-btn text color="primary" @click="$refs.menu.save(date)">
							OK
						</v-btn>
					</v-date-picker>
				</v-menu>
			</v-col>
			<v-col cols="12" md="2" sm="2">
				<v-btn color="primary" @click="handlefilter">filter</v-btn>
			</v-col>

			<v-col class="d-flex justify-end">
				<v-btn small color="primary" v-print="'#printPage'"
					><v-icon class="pr-1" size="19"> mdi-cloud-upload </v-icon>
					Export Voucher</v-btn
				></v-col
			>
		</v-row>
		<div class="white py-5 mt-5" id="printPage">
			<v-subheader class="justify-center mb-10">
				<h1>Journal Voucher</h1>
			</v-subheader>
			<v-subheader class="justify-end mr-15 mb-n5"> </v-subheader>
			<v-subheader class="ml-13 mb-n7 justify-content-between">
				<h2>Regular Payroll</h2>
				<div style="width: 210px" class="d-flex">
					<h4 class="mt-1 primary--text">{{ showDate }}</h4>
				</div>
			</v-subheader>
			<v-flex class="mb-10">
				<div class="col-xs-12 mx-15 pt-5">
					<table aria-label="" class="table-bordered w-100">
						<thead class="">
							<tr>
								<th scope="col">Item</th>
								<th scope="col">Description</th>
								<th scope="col">Credit</th>
								<th scope="col">Debit</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Gross Salary</td>
								<td></td>
								<td style="text-align: center">
									{{ formatFigure(setTotals?.grossSalary) }}
								</td>
								<td></td>
							</tr>
							<tr>
								<td>Income Tax</td>
								<td></td>
								<td></td>
								<td style="text-align: center">
									{{ formatFigure(setTotals?.incomeTax) }}
								</td>
							</tr>
							<tr>
								<td>Social Security (13.5%) SSNIT</td>
								<td></td>
								<td></td>
								<td style="text-align: center">
									{{ formatFigure(setTotals?.socialSecurity) }}
								</td>
							</tr>
							<tr>
								<td>Social Security Fund (5%) AXIS</td>
								<td></td>
								<td></td>
								<td style="text-align: center">
									{{ formatFigure(setTotals?.socialSecurityFund) }}
								</td>
							</tr>
							<tr>
								<td>Various Staff Accounts - Loan Deduction</td>
								<td></td>
								<td></td>
								<td style="text-align: center">
									{{ formatFigure(setTotals?.loanDeduction) }}
								</td>
							</tr>
							<tr>
								<td>Payroll Control</td>
								<td></td>
								<td></td>
								<td style="text-align: center">
									{{ formatFigure(setTotals?.netSalary) }}
								</td>
							</tr>
							<tr>
								<td>Student Loan Trust Fund</td>
								<td></td>
								<td></td>
								<td style="text-align: center">
									{{ formatFigure(setTotals?.studentLoanDeduction) }}
								</td>
							</tr>
							<tr>
								<td><strong> TOTAL </strong></td>
								<td></td>
								<td style="text-align: center">
									<strong
										>{{ formatFigure(setTotals?.grossSalary) }}
									</strong>
								</td>
								<td style="text-align: center">
									<strong>
										{{ formatFigure(setTotals?.creditTotals) }}
									</strong>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</v-flex>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import helpers from "../../services/helpers";
import {
	GET_JV_REPORT,
	GET_JV_REPORT_BY_DEPARTMENT,
} from "../../services/graphql/queries/columnSummations";
export default {
	mixins: [helpers],
	data: () => ({
		isloading: false,
		setTotals: {},
		date: "",
		menu: "",
		showDate: null,
	}),
	methods: {
		// formatFigure(item) {
		//   return item ? new Intl.NumberFormat('en-US' , {
		//     minimumFractionDigits: 2,
		//     maximumFractionDigits: 2
		//   }).format(item) : ''
		// },

		getDate() {
			const date = this.date || new Date();
			return moment(date).format("MMMM, YYYY");
		},

		async getTotalData() {
			this.showDate = this.getDate();
			this.$apollo
				.query({
					query: GET_JV_REPORT,
				})
				.then((response) => {
					const data = response.data;
					this.setTotals = data?.getJVReport;
				})
				.catch((error) => {
					console.log(error);
				});
		},

		async handlefilter() {
			this.isloading = true;
			this.$apollo
				.mutate({
					mutation: GET_JV_REPORT_BY_DEPARTMENT,
					variables: {
						yearMonth: this.date,
						startWith: "",
					},
				})
				.then((response) => {
					const data = response.data;
					console.log(data);
					this.showDate = this.getDate();
					this.setTotals = response.data?.getJVReportByDepartment;
				})
				.catch((error) => {
					console.log(error);
				});
			this.isloading = false;
		},

		resetData() {
			window.location.reload();
		},
	},
	created() {
		let today = new Date();
		this.date =
			today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2);
	},
	mounted() {
		this.getTotalData();
	},
};
</script>
<style scoped>
.v-toolbar__title {
	font-weight: bolder;
	font-size: 1.2rem;
}
.table-bordered {
	border: 1px solid black;
}
table {
	border-collapse: collapse;
}
th {
	border-bottom: 1px solid black;
	border-right: 1px solid black;
	text-align: center;
	font-weight: 400;
	padding: 0.4rem 0;
}
td {
	padding: 0.4rem 0;
	padding-left: 0.4rem;
	border-right: 1px solid black;
}
@media print {
	@page {
		size: landscape;
	}
}
</style>
